enum Constants {
    TOOLBAR_HEIGHT = 90,

    MAX_WIDTH = 1209,

    MEDIA_CHANGE_TABLET = 991,
    MEDIA_CHANGE_MOBILE = 545,
}

export default Constants;
