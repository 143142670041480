import CssBaseline from "@material-ui/core/CssBaseline";
import StylesProvider from "@material-ui/styles/StylesProvider";
import MaterialThemeProvider from "@material-ui/styles/ThemeProvider";
import { DefaultSeo } from "next-seo";
import { AppProps } from "next/app";
import React from "react";
import SimpleReactLightbox from "simple-react-lightbox";
import { Colours, Constants, ThemeProvider } from "src/styles";
import { createGlobalStyle } from "styled-components";
import Footer from "../components/widgets/footer/Footer";
import Toolbar from "../components/widgets/toolbar/Toolbar";
import "../styles/index.scss";
import MaterialTheme from "../styles/MaterialTheme";
import theme from "../styles/theme";
import styles from "./app.module.scss";

const GlobalStyle = createGlobalStyle`
    body {
        font-family: 'Kumbh Sans', sans-serif;
        color: #62778f;
        font-size: 18px;
        line-height: 1.667em;
        background-color: ${Colours.BACKGROUND};
        letter-spacing: 0.36px;
    }

    h1 {
        margin-top: 0;
        margin-bottom: 24px;
        font-family: Newsreader, sans-serif;
        color: #0f437f;
        font-size: 60px;
        line-height: 1.3em;
        font-weight: 600;

        @media screen and (max-width: ${Constants.MEDIA_CHANGE_TABLET}px) {
            margin-bottom: 16px;
            font-size: 42px;
        }
    }

    h2 {
        margin-top: 0;
        margin-bottom: 16px;
        font-family: Newsreader, sans-serif;
        color: #0f437f;
        font-size: 38px;
        line-height: 1.289em;
        font-weight: 600;

        @media screen and (max-width: ${Constants.MEDIA_CHANGE_TABLET}px) {
            margin-bottom: 16px;
            font-size: 32px;
        }
    }

    h3 {
        margin-top: 0;
        margin-bottom: 16px;
        color: #1e3d60;
        font-size: 24px;
        line-height: 1.5em;
        font-weight: 700;

        @media screen and (max-width: ${Constants.MEDIA_CHANGE_TABLET}px) {
            font-size: 22px;
        }
    }

    h4 {
        margin-top: 0;
        margin-bottom: 16px;
        color: #1e3d60;
        font-size: 20px;
        line-height: 1.5em;
        font-weight: 700;
    }

    h5 {
        margin-top: 0;
        margin-bottom: 16px;
        font-family: Newsreader, sans-serif;
        color: #0f437f;
        font-size: 18px;
        line-height: 1.444em;
        font-weight: 600;
    }

    h6 {
        margin-top: 0;
        margin-bottom: 10px;
        font-family: Newsreader, sans-serif;
        color: #0f437f;
        font-size: 16px;
        line-height: 1.5em;
        font-weight: 600;
    }

    p {
        margin-bottom: 20px;
    }

    a {
        transition: color 300ms ease;
        color: #064ea4;
        text-decoration: underline;
    }

    a:hover {
        color: #f99d77;
    }

    ul {
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 40px;
    }

    ol {
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 40px;
    }

    li {
    }

    img {
        display: inline-block;
        max-width: 100%;
    }

    label {
        display: block;
        color: #1e3d60;
        line-height: 0.111em;
        font-weight: 700;
    }

    strong {
        color: #0f437f;
        font-weight: 700;
    }

    blockquote {
        margin-bottom: 10px;
        padding: 70px 76px 70px 126px;
        border-left: 11px solid #f99d77;
        border-radius: 10px 20px 20px 10px;
        background-color: #fff;
        box-shadow: 0 5px 15px 0 rgba(30, 61, 96, 0.03);
        font-size: 24px;
        line-height: 1.583em;
    }

    figure {
        margin-bottom: 10px;
    }

    figcaption {
        margin-top: 5px;
        text-align: center;
    }

`;

export default function App(props: AppProps) {
    const { Component, pageProps } = props;

    return (
        <ThemeProvider theme={theme}>
            <StylesProvider injectFirst>
                <MaterialThemeProvider theme={MaterialTheme}>
                    <SimpleReactLightbox>
                        <>
                            <DefaultSeo
                                canonical={"https://rossmillarswimschool.co.nz/"}
                                facebook={{ appId: "348577079663969" }}
                                defaultTitle={"Ross Millar Swim School | Est. 1990"}
                                titleTemplate={"%s | Ross Millar Swim School | Est. 1990"}
                                description={
                                    "Ross Millar Swim School offers dedicated swimming tuition for all ages. We utilise the indoor heated swimming pool at Three Kings Primary School so lessons can run throughout the year."
                                }
                                openGraph={{
                                    type: "website",
                                    locale: "en_NZ",
                                    site_name: "Ross Millar Swim School | Est. 1990",
                                    title: "Ross Millar Swim School | Est. 1990",
                                    url: "https://rossmillarswimschool.co.nz//",
                                    description:
                                        "Ross Millar Swim School offers dedicated swimming tuition for all ages. We utilise the indoor heated swimming pool at Three Kings Primary School so lessons can run throughout the year.",
                                    images: [
                                        {
                                            url: "/images/classes/supa_squad/1.jpg",
                                        },
                                    ],
                                }}
                            />
                            <CssBaseline />
                            <GlobalStyle />
                            <Toolbar />
                            <div className={styles.container}>
                                <Component {...pageProps} />
                            </div>
                            <Footer />
                        </>
                    </SimpleReactLightbox>
                </MaterialThemeProvider>
            </StylesProvider>
        </ThemeProvider>
    );
}
