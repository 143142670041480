import Drawer from "@material-ui/core/Drawer";
import { useRouter } from "next/router";
import React, { PropsWithChildren, useCallback, useState } from "react";
import { IoClose, IoMenu } from "react-icons/io5";
import Button from "src/components/widgets/button/Button";
import Spacer from "src/components/widgets/spacer/Spacer";
import { Colours, Constants, spacing, styled } from "src/styles";

export type MobileProps = PropsWithChildren<{}>;

export default function Mobile(props: MobileProps) {
    const { ...otherProps } = props;

    const router = useRouter();
    const [open, setOpen] = useState(false);

    const onMenuClick = useCallback(() => {
        setOpen(true);
    }, []);

    const onMenuItemClick = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        setOpen(false);
        // @ts-ignore
        router.push(e.target?.href);
    }, []);

    const onClose = useCallback(() => {
        setOpen(false);
    }, []);

    return (
        <>
            <Wrapper {...otherProps}>
                <Spacer spacing={"xl"} />
                <MenuButton onClick={onMenuClick}>
                    <MenuIcon />
                </MenuButton>
                <Spacer spacing={"s"} />
            </Wrapper>
            <StyledDrawer open={open} anchor={"right"} onClose={onClose}>
                {/* <Link href={"/"} passHref>
          <Logo>
            <Image
              src={"/images/logo_toolbar.png"}
              width={250}
              height={50}
              quality={90}
              objectFit={"contain"} />
          </Logo>
        </Link> */}
                <Header>
                    <MenuButton onClick={onClose}>
                        <MenuClose />
                    </MenuButton>
                </Header>
                <Menu>
                    <MenuItem>
                        <a href={"/"} onClick={onMenuItemClick}>
                            Home
                        </a>
                    </MenuItem>
                    <MenuItem>
                        <a href={"/about#team"} onClick={onMenuItemClick}>
                            Our team
                        </a>
                    </MenuItem>
                    <MenuItem>
                        <a href={"/about"} onClick={onMenuItemClick}>
                            About
                        </a>
                    </MenuItem>
                    <MenuItem>
                        <a href={"/classes"} onClick={onMenuItemClick}>
                            Classes
                        </a>
                    </MenuItem>
                    <MenuItem>
                        <a  href={"https://ross-millar-swim-school.accounts.ud.io/login/"} target={"_blank"} rel={"noreferrer noopener"} onClick={onClose}>
                            Login
                        </a>
                    </MenuItem>
                </Menu>
                <Spacer spacing={"m"} />
                <Button href={"/contact"} onClick={onClose}>
                    Contact Us
                </Button>
            </StyledDrawer>
        </>
    );
}

const Wrapper = styled.div`
    display: none;
    flex-direction: row;

    @media screen and (max-width: ${Constants.MEDIA_CHANGE_TABLET}px) {
        display: flex;
    }
`;

const Menu = styled.ul`
    list-style: none;
    padding-left: 0;
`;

const MenuItem = styled.li`
    > a {
        line-height: 1.5em;
        font-weight: 700;
        text-decoration: none;
        font-size: 32px;
    }
`;

const StyledDrawer = styled(Drawer)`
    > .MuiPaper-root {
        box-shadow: none;
        width: 80%;
        max-width: 366px;
        padding: ${spacing("l")}px;
    }
`;
const Logo = styled.a`
    transform-style: preserve-3d;
    transition-property: transform;

    :hover {
        transform: scale3d(0.96, 0.96, 1.01);
    }
`;

const MenuButton = styled.div`
    width: 46px;
    height: 46px;
    background-color: ${Colours.BLUE};
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 300ms ease;

    :active {
        background-color: ${Colours.ORANGE};
    }
`;

const MenuIcon = styled(IoMenu)`
    color: white;
    font-size: 32px;
`;

const Header = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

const MenuClose = styled(IoClose)`
    color: white;
    font-size: 32px;
`;
