import tinycolor from "tinycolor2";

const Colours = {
    BACKGROUND: "#f9fafb",
    BLUE: "#064ea4",
    BLUE_DARK: "#0f437f",
    BLUE_LIGHT: tinycolor("#1e3d60").lighten(12).toHex8String(),
    WHITE: "#ffffff",
    ORANGE: "#fd8220",
    ORANGE_LIGHT: "#f99d77",
    GREY: "#becad7",
    GREY_DARK: "#e7eaee",
    GREY_TEXT: "#62778f",

    BUTTON_BLUE_DARK: "#0f437f",
};

export default Colours;
