import React, { PropsWithChildren } from "react";
import { Colours, constant, Constants, css, spacing, styled } from "src/styles";

export type SectionProps = PropsWithChildren<{
    className?: string;
    backgroundColour?: string;
    textColour?: string;
    center?: boolean;
}>;

export default function Section(props: SectionProps) {
    const { className, children, backgroundColour = Colours.WHITE, textColour = Colours.BLUE_DARK, center = false } = props;
    return (
        <Container className={className} backgroundColour={backgroundColour} textColour={textColour}>
            <Inner center={center}>{children}</Inner>
        </Container>
    );
}

const Container = styled.div<{
    backgroundColour: string;
    textColour: string;
}>`
    width: 100%;
    position: relative;
    background-color: ${({ backgroundColour }) => backgroundColour};
    color: ${({ textColour }) => textColour};

    h1,
    h2,
    h3,
    h4,
    h5,
    a {
        color: ${({ textColour }) => textColour};
    }
`;

const Inner = styled.div<{ center: boolean }>`
    position: relative;
    width: 100%;
    max-width: ${constant("MAX_WIDTH")}px;
    margin: 0 auto;
    padding: ${spacing("xl", 3)}px ${spacing("l")}px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    ${({ center }) =>
        center &&
        css`
            h1,
            h2,
            h3,
            h4,
            a {
                text-align: center;
            }
        `}
`;

const LeftBackground = styled.div`
    position: absolute;
    top: 0;
    left: -36px;
    bottom: 0;
    width: 39%;
    background-color: #064ea4;

    @media screen and (max-width: ${Constants.MEDIA_CHANGE_TABLET}px) {
        left: 0;
        top: auto;
        width: 100%;
        min-height: 30%;
    }
`;

const RightBackground = styled.div`
    position: absolute;
    top: 0;
    right: -36px;
    bottom: 0;
    width: 39%;
    background-color: #064ea4;

    @media screen and (max-width: ${Constants.MEDIA_CHANGE_TABLET}px) {
        display: none;
    }
    
`;

const Header = styled.h2`
    text-align: center;
    margin-bottom: 36px;
    /* text-decoration: underline; */
`;

const Paragraph = styled.p``;

Section.LeftBackground = LeftBackground;
Section.RightBackground = RightBackground;
Section.Header = Header;
