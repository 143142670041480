import { createTheme } from "@material-ui/core/styles";

const MaterialTheme = createTheme({
    typography: {
        fontFamily: "\"Open Sans\", sans-serif",
    },
    spacing: 9,
    overrides: {
        MuiInputBase: {
            input: {
                height: 34,
            },
        },
        MuiTextField: {
            root: {
                display: "flex",
            },
        },
        MuiInput: {
            root: {
                border: "1px solid #CDCDCD",
                borderRadius: 4,
            },
            input: {
                minHeight: 28,
                fontSize: 16,
                padding: "4px 9px",
            },
            formControl: {
                "label + &": {
                    marginTop: 20,
                },
            },
            inputMultiline: {
                minHeight: 108,
            },
        },
        MuiSelect: {
            selectMenu: {
                display: "flex",
                alignItems: "center",
            },
        },
    },
    props: {
        MuiInput: {
            disableUnderline: true,
        },
        MuiInputLabel: {
            shrink: true,
        },
    },
});

export default MaterialTheme;
