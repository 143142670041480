import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { Colours, Constants, constant, spacing, styled } from "src/styles";
import Button from "../button/Button";
import Spacer from "../spacer/Spacer";
import Desktop from "./componnets/desktop/Desktop";
import Mobile from "./componnets/mobile/Mobile";
// import logo_toolbar from "../../../resources/images/logo_toolbar.png";
// import logo_toolbar_mobile from "../../../resources/images/logo_toolbar_mobile.png";

export type ToolbarProps = {};

export default function Toolbar(props: ToolbarProps) {
    const router = useRouter();

    useEffect(() => {
        const routes = ["/", "/team", "/about", "/contact", "/classes"];
        routes.forEach((route) => {
            router.prefetch(route).then(() => {
                console.log("route prefetched", route);
            });
        });
    }, []);

    const isContact = router.asPath.startsWith("/contact");

    return (
        <Container>
            <Bar>
                <Link href={"/"} passHref>
                    <Logo>
                        <Image src={"/images/rmss_logo.png"} width={240} height={61} quality={90} objectFit={"contain"} />
                    </Logo>
                </Link>
                <Center>
                    <Desktop />
                </Center>
                <Right>
                    <ContactUsButton href={isContact ? "/classes" : "/contact"}>
                        {isContact ? "View Classes" : "Contact us"}
                    </ContactUsButton>
                    <Mobile />
                </Right>
            </Bar>
        </Container>
    );
}

const Container = styled.div`
    width: 100%;
    position: fixed;
    background-color: ${Colours.WHITE};
    top: 0;
    right: 0;
    left: 0;
    height: 98px;
    z-index: 1200;
`;

const Bar = styled.div`
    //height: ${Constants.TOOLBAR_HEIGHT}px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: ${spacing("m")}px;
    max-width: ${constant("MAX_WIDTH")}px;
    margin: 0 auto;
`;

const Logo = styled.a`
    transform-style: preserve-3d;
    transition-property: transform;
    padding-top: 9px;

    :hover {
        transform: scale3d(0.96, 0.96, 1.01);
    }
`;

const Center = styled.div`
    display: flex;
    flex-direction: row;
`;

const Right = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const ContactUsButton = styled(Button)`
    @media screen and (max-width: ${Constants.MEDIA_CHANGE_MOBILE}px) {
        display: none;
    }
`;
