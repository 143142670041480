import React from "react";
import { Colours, Constants, spacing, styled } from "src/styles";
import Image from "next/image";
import Link from "next/link";
import Button from "../button/Button";
import Section from "../section/Section";
import Spacer from "../spacer/Spacer";

export type FooterProps = {};

export default function Footer(props: FooterProps) {
    return (
        <>
            <Container backgroundColour={Colours.BLUE_DARK} textColour={Colours.WHITE}>
                <TopRow>
                    <Contact>
                        <h2>Contact us</h2>
                        <h4>For more information on our swimming lessons, please give us a call today</h4>
                        <Button href={"/contact"}>Contact Us</Button>
                        <br />
                    </Contact>
                    <Location>
                        <h3>Where to find us</h3>
                        <a target={"_blank"}
                           href={
                               "https://www.google.com/maps/place/Three+Kings+School/@-36.9080206,174.75898,15z/data=!4m2!3m1!1s0x0:0x2e93a709f545f5d1?sa=X&ved=2ahUKEwj-uqWEypTyAhUExzgGHdT0DMgQ_BIwEnoECD8QBQ"
                           }
                           rel={"noreferrer noopener"}>
                           Three Kings Primary School
                           <br />
                           Te Tatua O Rakataura
                           <br />
                           944 Mt Eden Road
                           <br />
                           Three Kings
                        </a>
                        <br />
                        <a href={"tel:096241456"}>09 624-1456</a>
                    </Location>
                </TopRow>
                <MiddleRow>
                    <FooterNav>
                        <FooterNavItem>
                            <Link href={"/"}>Home</Link>
                        </FooterNavItem>
                        <FooterNavItem>
                            <Link href={"/team"}>Team</Link>
                        </FooterNavItem>
                        <FooterNavItem>
                            <Link href={"/about"}>About</Link>
                        </FooterNavItem>
                        <FooterNavItem>
                            <Link href={"/classes"}>Classes</Link>
                        </FooterNavItem>
                        <FooterNavItem>
                            <Link href={"/contact"}>Contact</Link>
                        </FooterNavItem>
                    </FooterNav>
                </MiddleRow>
                <BottomRow>
                    <span>Ross Millar Swim School {new Date().getFullYear()}</span>
                    <Spacer spacing={"m"} />
                    <BuiltBy>
                        Built by{" "}
                        <a href={"https://delacour.co.nz?ref"} target={"_blank"} rel={"noreferrer noopener"}>
                            Delacour
                        </a>
                    </BuiltBy>
                </BottomRow>
            </Container>

            {/* <div className={styles.details}> */}
            {/* <span>Our swim school is located at Three Kings School indoor heated pool, */}
            {/*    {"\n"} */}
            {/*    the pool is heated to 32 degrees.</span> */}

            {/* <br /> */}
            {/* <a href={"tel:096241456"}>09 6241456</a> */}
            {/* </div> */}
        </>
    );
}

const Container = styled(Section)`
    > div {
        margin: auto;
        padding: ${spacing("l")}px;
    }
`;

const Location = styled.div`
    padding: 18px; 
    
    > a {
        display: block;
        color: white;

        :hover {
            ${Colours.ORANGE}
        }
    }
`;

const TopRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: ${spacing("xl", 2)}px 0;
    justify-content: space-between;

    @media screen and (max-width: ${Constants.MEDIA_CHANGE_TABLET}px) {
    }
`;

const MiddleRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: ${spacing("xl")}px 0;
    flex: 1;
`;

const BottomRow = styled.div`
    width: 100%;
    background-color: ${Colours.BLUE_DARK};
    padding: 18px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    /* span {
        font-weight: 900;
        color: white;
        font-size: 16px;
    } */

    a {
        color: white;
        font-weight: 600;

        :hover {
            color: ${Colours.ORANGE_LIGHT};
        }
    }
`;

const BuiltBy = styled.span`
`;

const Contact = styled.div`
    text-align: left;
    padding: 18px;

    > a {
        margin-bottom: 36px;
        
      
    }
`;

const FooterNav = styled.ul`
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 0 auto;
    padding-inline: 0;
`;

const FooterNavItem = styled.li`
    padding: ${spacing("xs")}px ${spacing("m")}px;

    a {
        transition: color 350ms ease;
        color: #becad7;
        line-height: 1.111em;
        font-weight: 700;
        text-decoration: none;

        &:hover {
            color: ${Colours.ORANGE_LIGHT};
        }
    }
`;
