import Link from "next/link";
import React, { Ref } from "react";
import { Colours, Constants, css, spacing, styled } from "src/styles";
import tinycolor from "tinycolor2";

export type ButtonProps = {
    colour?: string;
    plain?: boolean;
    disabled?: boolean;
} & React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;

function Button(props: ButtonProps, ref: Ref<HTMLAnchorElement>) {
    const { className, colour = Colours.ORANGE, plain = false, disabled = false, href, ...otherProps } = props;

    const button = (
        <Btn {...otherProps} className={className} colour={colour} plain={plain} disabled={disabled}
            ref={ref} />
    );

    if (href) {
        return (
            <Link href={href} passHref>
                {button}
            </Link>
        );
    }

    return button;
}

export default React.forwardRef<HTMLAnchorElement, ButtonProps>(Button);

const Btn = styled.a<{ colour: string; plain: boolean; disabled: boolean }>`
    display: inline-block;
    background-color: ${(p) => p.colour};
    padding: ${spacing("m")}px ${spacing("l")}px;
    //border-radius: 1000px;
    transition: transform 300ms ease, background-color 300ms ease, -webkit-transform 300ms ease;
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    line-height: 1.125em;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    font-family: "Kumbh Sans", sans-serif;
    letter-spacing: 0.08em;
    outline: 0;

    :hover {
        text-decoration: none;
        transform: scale3d(0.96, 0.96, 1.01);
        /*background-color: ${(p) => tinycolor(p.colour).darken(7).toHex8String()};*/
        color: #fff;
    }

    ${({ plain }) =>
        plain &&
        css`
            background-color: ${Colours.GREY_DARK};
            color: ${Colours.BLUE_DARK};

            :hover {
                background-color: ${Colours.GREY};
                color: ${Colours.BLUE_DARK};
            }
        `}

    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.5;
            color: ${Colours.BLUE_DARK};
            transform: none;
            pointer-events: none;
        `}
`;
