import Link from "next/link";
import React from "react";
import { Colours, Constants, spacing, styled } from "src/styles";

export type DesktopProps = {};

export default function Desktop(props: DesktopProps) {
    const {} = props;

    return (
        <Wrapper>
            <NavItem>
                <Link href={"/"}>Home</Link>
            </NavItem>
            <NavItem>
                <Link href={"/about#team"}>Our team</Link>
            </NavItem>
            <NavItem>
                <Link href={"/about"}>About</Link>
            </NavItem>
            <NavItem>
                <Link href={"/classes"}>Classes</Link>
            </NavItem>
            <NavItem>
                <a href={"https://ross-millar-swim-school.accounts.ud.io/login/"} target={"_blank"} rel={"noreferrer noopener"}>Login</a>
            </NavItem>
        </Wrapper>
    );
}

const Wrapper = styled.ul`
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    @media screen and (max-width: ${Constants.MEDIA_CHANGE_TABLET}px) {
        display: none;
    }
`;

const NavItem = styled.li`
    a {
        transition: color 350ms ease;
        color: #1e3d60;
        line-height: 1.111em;
        font-weight: 700;
        text-decoration: none;
        padding: ${spacing("s")}px ${spacing("m")}px;

        &:hover {
            color: ${Colours.ORANGE};
        }
    }
`;
