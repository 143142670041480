import { DefaultTheme } from "styled-components";
import Colours from "./colours";
import Constants from "./constants";

const Theme: DefaultTheme = {
    constants: {
        ...Constants,
    },
    radius: {
        xs: 4,
        s: 9,
        m: 18,
        l: 27,
        xl: 36,
        round: 999,
    },
    spacing: {
        xs: 5,
        s: 8,
        m: 16,
        l: 24,
        xl: 32,
        xxl: 40,
    },
    font: {
        family: {
            newsreader: "",
            kumbhsans: "",
        },
        size: {
            xs: 12,
            s: 14,
            m: 16,
            l: 18,
            xl: 27,
        },
        weight: {
            light: "300",
            normal: "500",
            semibold: "700",
            bold: "900",
        },
    },
    colours: {
        ...Colours,
    },
};

export default Theme;
