import React from "react";
import { styled } from "src/styles";
import { DefaultTheme } from "styled-components";

const Spacer = styled.div<{ spacing: keyof DefaultTheme["spacing"] }>`
    display: inline-block;
    width: ${(p) => p.theme.spacing[p.spacing]}px;
    height: ${(p) => p.theme.spacing[p.spacing]}px;
`;

export default Spacer;
