import * as styledComponents from "styled-components";
import { ThemedStyledProps } from "styled-components";
import Theme from "./theme";

export const {
    default: styled,

    css,

    withTheme,
    ThemeProvider,
    ThemeConsumer,
    ThemeContext,
    useTheme,

    isStyledComponent,
} = styledComponents;

export const theme = Theme;

export function spacing(size: keyof typeof Theme.spacing, multiplier: number = 1) {
    return <P = object>(p: ThemedStyledProps<P, typeof Theme>) => p.theme.spacing[size] * multiplier;
}

export function radius(size: keyof typeof Theme.radius) {
    return <P = object>(p: ThemedStyledProps<P, typeof Theme>) => p.theme.radius[size];
}

export function fontSize(size: keyof typeof Theme.font.size) {
    return <P = object>(p: ThemedStyledProps<P, typeof Theme>) => p.theme.font.size[size];
}

export function constant(constKey: keyof typeof Theme.constants) {
    return <P = object>(p: ThemedStyledProps<P, typeof Theme>) => p.theme.constants[constKey];
}
